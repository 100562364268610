<template>
  <div class="object-full" v-if="selectedObject">
    <swiper class="photos" :options="{slidesPerView: 'auto', touchRatio: 2}">
      <swiper-slide v-for="(img, index) in selectedObject.images" :key="index">
        <img :src="img" alt />
      </swiper-slide>
    </swiper>

    <div class="content">
      <div class="left-side">
        <div class="title">{{selectedObject.title}}</div>
        <object-item-adds class="adds" :adds="selectedObject.additions" />
        <div class="qrcode" v-if="selectedObject.qr_code">
          <div>
            <div class="qr">
              <img :src="selectedObject.qr_code" alt />
            </div>
            <div class="description">Забронировать номер c помощью QR-кода</div>
          </div>
        </div>
      </div>

      <scrolled-content class="right-side">
        <tag :title="selectedObject.type.name" />
        <!-- <txt class="text" :content="selectedObject.description" /> -->
        <div class="text" v-html="$removeLinkTags(selectedObject.description)"></div>
      </scrolled-content>
    </div>

    <!-- <div class="imap-fullpopup-map" v-if="selectedObject.location.length">
        <div class="imap-fullpopup-map__cont">
          <yandex-map
            :coords="selectedObject.location"
            zoom="16"
            :controls="['zoomControl']"
            @map-was-initialized="mapInitHandler"
          >
            <ymap-marker
              marker-type="Placemark"
              :marker-id="selectedObject.id"
              :coords="selectedObject.location"
              :icon="{color:'blueDot'}"
            />
          </yandex-map>
        </div>
        <div class="imap-fullpopup-map__btn" @click="goToMap()">
          <span class="go_to_" data-container="interactive">Показать на карте</span>
        </div>
    </div>-->
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import ObjectItemAdds from "@/components/Parts/ObjectItemAdds";
import ScrolledContent from "@/components/Wrappers/ScrolledContent";
import Tag from "@/components/Parts/TagSmall";
// import NearObjects from "@/components/NearObjects";

export default {
  components: { ObjectItemAdds, ScrolledContent, Tag }, //, NearObjects },
  data() {
    return {
      map: null
    };
  },
  computed: {
    ...mapGetters(["selectedObject"])
  },
  methods: {
    goToMap() {
      this.$router.push({ path: `/map/${this.selectedObject.type.id}` });
    },
    mapInitHandler(map) {
      this.map = map;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.object-full {
  .photos {
    margin-top: 56px;

    .swiper-slide {
      width: 884px;
      height: 442px;

      margin-left: 32px;
      margin-right: 12px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &:last-child {
        margin-right: 32px;
      }
    }
  }

  .content {
    margin-top: 32px;
    display: flex;

    .left-side {
      width: 488px;
      margin-left: 32px;
      margin-right: 40px;
      text-align: left;

      .title {
        width: 488px;
        font-size: 50px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.12;
        letter-spacing: normal;
        color: rgba(#fff, 0.97);
        padding-bottom: 40px;
        border-bottom: 4px solid rgba($color_grey_3, 0.6);
        margin-bottom: 32px;
      }

      .qrcode {
        width: 100%;
        border-top: 4px solid rgba($color_grey_3, 0.6);
        margin-top: 32px;
        padding-top: 32px;
        & > div {
          overflow: hidden;
          width: 364px;
          height: 128px;
          border-radius: 12px;
          background-image: $grad_yellow_grey2;
          display: flex;
          .qr {
            width: 128px;
            height: 128px;
            background: #fff;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .description {
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
            font-size: 20px;
            font-weight: 500;
            line-height: 1.4;
            letter-spacing: -0.2px;
            color: #fff;
            width: 187px;
            margin: 22px 24px;
          }
        }
      }
    }
    .right-side {
      width: 508px;
      // width: auto;
      max-height: 646px;

      .text {
        margin-top: 32px;
        width: 488px;

        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.1px;
        color: rgba(#fff, 0.8);
        position: relative;
        padding-bottom: 32px;
      }
    }
  }
}
</style>
